import { Keyframes, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { CarouselIndicator, Color } from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { BannerShadow } from "../../lib/constants/Shadow";
import { Banner } from "../../types/banner";
import { device } from "../../utils";
import { NextLink } from "../NextLink";

enum Positions {
  Center = 1,
  Left = 2,
  LeftLeft = 3,
  Right = 4,
  RightRight = 5,
  Hidden = 6,
  OutsideLeft = 7,
  OutsideRight = 8,
}

const PC_BANNER_WIDTH = 400;
const MOBILE_BANNER_WIDTH = 300;

const _sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

type TurnFrom = "left" | "right" | null;

type Props = {
  className?: string;
  banners: Banner[];
  maxBannerHeight?: string;
};

const BannerSlider: React.ForwardRefExoticComponent<Props> = forwardRef<
  HTMLDivElement,
  Props
>(function InternalBannerSlider(
  { className, banners, maxBannerHeight = "186px" }: Props,
  ref,
): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [targetIndex, setTargetIndex] = useState(0);
  const [fadeInBanners, setFadeInBanners] = useState<Banner[]>([]);
  const [turnFrom, setTrunFrom] = useState<TurnFrom>(null);
  const [moveAmount, setMoveAmount] = useState(0);
  const [duringAnimation, setDuringAnimation] = useState(false);

  const _banners = useMemo(() => {
    if (banners.length >= 5) {
      return banners;
    } else if (banners.length > 0) {
      let __banners = banners;
      while (__banners.length < 5) {
        __banners = __banners.concat(banners);
      }
      __banners = __banners.slice(0, 5);
      return __banners;
    } else {
      return [];
    }
  }, [banners]);

  const updateIndexes = async (index: number) => {
    setTargetIndex(index);
    setDuringAnimation(true);
    await _sleep(800);
    setSelectedIndex(index);
    setDuringAnimation(false);
  };

  const handleClick = useCallback(
    (index: number) => {
      if (!duringAnimation) {
        let _turnFrom: TurnFrom = null;
        let _moveAmount = 0;
        if (selectedIndex === 0 && index === _banners.length - 1) {
          _moveAmount = 1;
          _turnFrom = "left";
        } else if (selectedIndex === _banners.length - 1 && index === 0) {
          _moveAmount = 1;
          _turnFrom = "right";
        } else if (selectedIndex > index) {
          _moveAmount = selectedIndex - index;
          _turnFrom = "left";
        } else if (selectedIndex < index) {
          _moveAmount = index - selectedIndex;
          _turnFrom = "right";
        } else {
          _turnFrom = null;
        }
        setMoveAmount(_moveAmount);
        setTrunFrom(_turnFrom);
        if (_turnFrom === "left") {
          const newBanners: Banner[] = [];
          let fadeInIndex = index + 1 < _banners.length ? index + 1 : 0;
          for (let i = 1; i < _moveAmount; i++) {
            fadeInIndex =
              fadeInIndex + 1 < _banners.length ? fadeInIndex + 1 : 0;
          }
          fadeInIndex =
            fadeInIndex - 1 >= 0 ? fadeInIndex - 1 : _banners.length - 1;
          fadeInIndex =
            fadeInIndex - 1 >= 0 ? fadeInIndex - 1 : _banners.length - 1;
          for (let i = 0; i < _moveAmount; i++) {
            fadeInIndex =
              fadeInIndex - 1 >= 0 ? fadeInIndex - 1 : _banners.length - 1;
            const fadeInBanner = _banners[fadeInIndex];
            fadeInBanner && newBanners.push(fadeInBanner);
          }
          setFadeInBanners(newBanners);
        } else if (_turnFrom === "right") {
          const newBanners: Banner[] = [];
          let fadeInIndex = index - 1 >= 0 ? index - 1 : _banners.length - 1;
          for (let i = 1; i < _moveAmount; i++) {
            fadeInIndex =
              fadeInIndex - 1 >= 0 ? fadeInIndex - 1 : _banners.length - 1;
          }
          fadeInIndex = fadeInIndex + 1 < _banners.length ? fadeInIndex + 1 : 0;
          fadeInIndex = fadeInIndex + 1 < _banners.length ? fadeInIndex + 1 : 0;
          for (let i = 0; i < _moveAmount; i++) {
            fadeInIndex =
              fadeInIndex + 1 < _banners.length ? fadeInIndex + 1 : 0;
            const fadeInBanner = _banners[fadeInIndex];
            fadeInBanner && newBanners.push(fadeInBanner);
          }
          setFadeInBanners(newBanners);
        }
        updateIndexes(index);
      }
    },
    [_banners, duringAnimation, selectedIndex, setFadeInBanners],
  );

  const moveToNext = useCallback(() => {
    if (!duringAnimation) {
      const nextIndex =
        selectedIndex + 1 < _banners.length ? selectedIndex + 1 : 0;
      setMoveAmount(1);
      setTrunFrom("right");
      const newBanners: Banner[] = [];
      let fadeInIndex = nextIndex + 1 < _banners.length ? nextIndex + 1 : 0;
      fadeInIndex = fadeInIndex + 1 < _banners.length ? fadeInIndex + 1 : 0;
      const fadeInBanner = _banners[fadeInIndex];
      fadeInBanner && newBanners.push(fadeInBanner);
      setFadeInBanners(newBanners);
      updateIndexes(nextIndex);
    }
  }, [duringAnimation, selectedIndex, _banners]);

  const callbackRef = useRef(moveToNext);

  useEffect(() => {
    callbackRef.current = moveToNext;
  }, [moveToNext]);

  useEffect(() => {
    if (banners.length > 1) {
      const id = setInterval(() => {
        callbackRef.current();
      }, 5000);
      return () => clearInterval(id);
    }
  }, [banners]);

  return (
    <Root className={className} ref={ref}>
      <CardsWrapper height={maxBannerHeight}>
        {_banners.map((banner, idx) => (
          <BannerWrapper
            key={`card-wrapper-${idx}`}
            length={_banners.length}
            selectedIndex={selectedIndex}
            targetIndex={targetIndex}
            index={idx}
            turnFrom={turnFrom}
            moveAmount={moveAmount}
            duringAnimation={duringAnimation}
            height={maxBannerHeight}
            width={PC_BANNER_WIDTH}
          >
            <NextLink href={banner.link}>
              <Image src={banner.image} alt="seminar" fill />
            </NextLink>
          </BannerWrapper>
        ))}
        {turnFrom &&
          fadeInBanners.map((fadeInBanner, idx) => (
            <FadeInCardWrapper
              key={`fade-in-${idx}`}
              turnFrom={turnFrom}
              duringAnimation={duringAnimation}
              moveAmount={moveAmount - idx}
              height={maxBannerHeight}
              width={PC_BANNER_WIDTH}
            >
              <NextLink href={fadeInBanner.link}>
                <Image src={fadeInBanner.image} alt="seminar" fill />
              </NextLink>
            </FadeInCardWrapper>
          ))}
      </CardsWrapper>
      <_CarouselIndicator
        numOfDots={_banners.length}
        current={selectedIndex}
        onClick={handleClick}
      />
    </Root>
  );
});

function calcPositionIndex(
  index: number,
  selectedIndex: number,
  length: number,
): number {
  return index - selectedIndex >= 0
    ? index - selectedIndex
    : index - selectedIndex + length;
}

function calcPosition(
  index: number,
  length: number,
  turnFrom?: TurnFrom,
): number {
  switch (index) {
    case 0:
      return Positions.Center;
    case 1:
      return Positions.Right;
    case length - 1:
      return Positions.Left;
    case 2:
      return Positions.RightRight;
    case length - 2:
      return Positions.LeftLeft;
    default:
      return turnFrom === "right"
        ? Positions.OutsideRight
        : Positions.OutsideLeft;
  }
}

function calcScale(position: Positions) {
  return position === Positions.Center ? 1 : 0.645;
}

function calcMargin(position: Positions, prePosition?: Positions) {
  switch (position) {
    case Positions.Center:
      return 0;
    case Positions.Left:
      return 695;
    case Positions.Right:
      return -695;
    case Positions.LeftLeft:
      return prePosition && prePosition === Positions.RightRight ? -1803 : 1249;
    case Positions.RightRight:
      return prePosition && prePosition === Positions.RightRight ? 1803 : -1249;
    case Positions.OutsideLeft:
      return 1803;
    case Positions.OutsideRight:
      return -1803;
    default:
      return 0;
  }
}

function calcOpacity(position: Positions, prePosition?: Positions) {
  switch (position) {
    case Positions.Center:
      return 1;
    case Positions.Left:
      return 1;
    case Positions.Right:
      return 1;
    case Positions.Hidden:
      return 0;
    case Positions.LeftLeft:
      return prePosition ? (prePosition === Positions.Left ? 1 : 0) : 1;
    case Positions.RightRight:
      return prePosition ? (prePosition === Positions.Right ? 1 : 0) : 1;
    case Positions.Hidden:
      return 0;
    default:
      return 0;
  }
}

function makeKeyframeAmount1(
  index: number,
  selectedIndex: number,
  targetIndex: number,
  length: number,
  turnFrom: "left" | "right",
): Keyframes {
  const startPosition = calcPosition(
    calcPositionIndex(index, selectedIndex, length),
    length,
  );
  const endPosition =
    turnFrom === "left"
      ? startPosition === Positions.RightRight
        ? Positions.OutsideRight
        : calcPosition(calcPositionIndex(index, targetIndex, length), length)
      : startPosition === Positions.LeftLeft
        ? Positions.OutsideLeft
        : calcPosition(calcPositionIndex(index, targetIndex, length), length);
  const startScale = calcScale(startPosition);
  const endScale = calcScale(endPosition);
  const startMargin = calcMargin(startPosition);
  const endMargin = calcMargin(endPosition, startPosition);
  const startOpacity = calcOpacity(startPosition);
  const endOpacity = calcOpacity(endPosition, startPosition);

  return keyframes`
  0% {
    transform: ${`scale(${startScale},${startScale})`};
    margin-right: ${startMargin}px;
    opacity: ${startOpacity};
  }
  100% {    
    transform: ${`scale(${endScale},${endScale})`};
    margin-right: ${endMargin}px;
    opacity: ${endOpacity};
  }
  `;
}

function makeKeyframeAmount2(
  index: number,
  selectedIndex: number,
  targetIndex: number,
  length: number,
  turnFrom: "left" | "right",
): Keyframes {
  const startPosition = calcPosition(
    calcPositionIndex(index, selectedIndex, length),
    length,
    turnFrom,
  );
  let endPosition;
  if (turnFrom === "left") {
    switch (startPosition) {
      case Positions.RightRight:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.Right:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.Center:
        endPosition = Positions.RightRight;
        break;
      case Positions.Left:
        endPosition = Positions.Right;
        break;
      case Positions.LeftLeft:
        endPosition = Positions.Center;
        break;
      default:
        endPosition = calcPosition(
          calcPositionIndex(index, targetIndex, length),
          length,
          turnFrom,
        );
        break;
    }
  } else {
    switch (startPosition) {
      case Positions.RightRight:
        endPosition = Positions.Center;
        break;
      case Positions.Right:
        endPosition = Positions.Left;
        break;
      case Positions.Center:
        endPosition = Positions.LeftLeft;
        break;
      case Positions.Left:
        endPosition = Positions.OutsideLeft;
        break;
      case Positions.LeftLeft:
        endPosition = Positions.OutsideLeft;
        break;
      default:
        endPosition = calcPosition(
          calcPositionIndex(index, targetIndex, length),
          length,
          turnFrom,
        );
        break;
    }
  }
  const startScale = calcScale(startPosition);
  const endScale = calcScale(endPosition);
  const startMargin = calcMargin(startPosition);
  const endMargin = calcMargin(endPosition);
  const startOpacity = calcOpacity(startPosition);
  const endOpacity = calcOpacity(endPosition);
  if (
    startPosition === Positions.Left ||
    startPosition === Positions.Center ||
    startPosition === Positions.Right ||
    startPosition === Positions.LeftLeft ||
    startPosition === Positions.RightRight
  ) {
    return keyframes`
    0% {
      transform: ${`scale(${startScale},${startScale})`};
      margin-right: ${startMargin}px;
      opacity: ${startOpacity};
    }
    ${
      turnFrom === "left" &&
      startPosition === Positions.Right &&
      `
      30% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
        opacity: ${endOpacity};
      }
      `
    }    
    ${
      turnFrom === "right" &&
      startPosition === Positions.Left &&
      `
      30% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
        opacity: ${endOpacity};
      }
      `
    } 
    ${
      startPosition === Positions.Left &&
      endPosition === Positions.Right &&
      `40% {
      transform: scale(1,1);
      opacity: 1;
    }`
    }
    ${
      startPosition === Positions.Right &&
      endPosition === Positions.Left &&
      `40% {
      transform: scale(1,1);
      opacity: 1;
    }`
    }
    ${
      startPosition === Positions.Center &&
      `
      60% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
        opacity: ${endOpacity};
      }
      `
    }
    100% {    
      transform: ${`scale(${endScale},${endScale})`};
      margin-right: ${endMargin}px;
      opacity: ${endOpacity};
    }
    `;
  } else {
    return keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
  `;
  }
}

function makeKeyframeAmount3(
  index: number,
  selectedIndex: number,
  targetIndex: number,
  length: number,
  turnFrom: "left" | "right",
): Keyframes {
  const startPosition = calcPosition(
    calcPositionIndex(index, selectedIndex, length),
    length,
    turnFrom,
  );
  let endPosition;
  if (turnFrom === "left") {
    switch (startPosition) {
      case Positions.RightRight:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.Right:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.Center:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.Left:
        endPosition = Positions.RightRight;
        break;
      case Positions.LeftLeft:
        endPosition = Positions.Right;
        break;
      default:
        endPosition = calcPosition(
          calcPositionIndex(index, targetIndex, length),
          length,
          turnFrom,
        );
        break;
    }
  } else {
    switch (startPosition) {
      case Positions.RightRight:
        endPosition = Positions.Left;
        break;
      case Positions.Right:
        endPosition = Positions.LeftLeft;
        break;
      case Positions.Center:
        endPosition = Positions.OutsideLeft;
        break;
      case Positions.Left:
        endPosition = Positions.OutsideLeft;
        break;
      case Positions.LeftLeft:
        endPosition = Positions.OutsideLeft;
        break;
      default:
        endPosition = calcPosition(
          calcPositionIndex(index, targetIndex, length),
          length,
          turnFrom,
        );
        break;
    }
  }
  const startScale = calcScale(startPosition);
  const endScale = calcScale(endPosition);
  const startMargin = calcMargin(startPosition);
  const endMargin = calcMargin(endPosition);
  const startOpacity = calcOpacity(startPosition);
  const endOpacity = calcOpacity(endPosition);
  if (
    startPosition === Positions.Left ||
    startPosition === Positions.Center ||
    startPosition === Positions.Right ||
    startPosition === Positions.LeftLeft ||
    startPosition === Positions.RightRight
  ) {
    return keyframes`
    0% {
      transform: ${`scale(${startScale},${startScale})`};
      margin-right: ${startMargin}px;
      opacity: ${startOpacity};
    }
    ${
      turnFrom === "left" &&
      startPosition === Positions.Right &&
      `
      20% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
        opacity: ${endOpacity};
      }
      `
    }
    ${
      turnFrom === "right" &&
      startPosition === Positions.Left &&
      `
      20% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
        opacity: ${endOpacity};
      }
      `
    }
    ${
      turnFrom === "left" &&
      startPosition === Positions.Left &&
      `
      30% {
        transform: scale(1,1);
        opacity: ${startOpacity};
      }
      `
    }    
    ${
      turnFrom === "right" &&
      startPosition === Positions.Right &&
      `
      30% {
        transform: scale(1,1);
        opacity: ${startOpacity};
      }
      `
    }
    ${
      startPosition === Positions.Center &&
      `
      85% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
      }
      `
    }

    100% {    
      transform: ${`scale(${endScale},${endScale})`};
      margin-right: ${endMargin}px;
      opacity: ${endOpacity};
    }
    `;
  } else {
    return keyframes`
  0% {
    opacity: 0;
  }
  100% {    
    opacity: 0;
  }
  `;
  }
}

function makeKeyframeAmount4(
  index: number,
  selectedIndex: number,
  length: number,
  turnFrom: "left" | "right",
): Keyframes {
  const startPosition = calcPosition(
    calcPositionIndex(index, selectedIndex, length),
    length,
    turnFrom,
  );
  let endPosition;
  if (turnFrom === "left") {
    switch (startPosition) {
      case Positions.RightRight:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.Right:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.Center:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.Left:
        endPosition = Positions.OutsideRight;
        break;
      case Positions.LeftLeft:
        endPosition = Positions.RightRight;
        break;
      default:
        endPosition = Positions.Hidden;
        break;
    }
  } else {
    switch (startPosition) {
      case Positions.RightRight:
        endPosition = Positions.LeftLeft;
        break;
      case Positions.Right:
        endPosition = Positions.OutsideLeft;
        break;
      case Positions.Center:
        endPosition = Positions.OutsideLeft;
        break;
      case Positions.Left:
        endPosition = Positions.OutsideLeft;
        break;
      case Positions.LeftLeft:
        endPosition = Positions.OutsideLeft;
        break;
      default:
        endPosition = Positions.Hidden;
        break;
    }
  }
  const startScale = calcScale(startPosition);
  const endScale = calcScale(endPosition);
  const startMargin = calcMargin(startPosition);
  const endMargin = calcMargin(endPosition);
  const startOpacity = calcOpacity(startPosition);
  const endOpacity = calcOpacity(endPosition);
  if (
    startPosition === Positions.Left ||
    startPosition === Positions.Center ||
    startPosition === Positions.Right ||
    startPosition === Positions.LeftLeft ||
    startPosition === Positions.RightRight
  ) {
    return keyframes`
    0% {
      transform: ${`scale(${startScale},${startScale})`};
      margin-right: ${startMargin}px;
      opacity: ${startOpacity};
    }
    ${
      turnFrom === "left" &&
      startPosition === Positions.Right &&
      `
      20% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
        opacity: ${endOpacity};
      }
      `
    }
    ${
      turnFrom === "right" &&
      startPosition === Positions.Left &&
      `
      20% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
        opacity: ${endOpacity};
      }
      `
    }
    ${
      turnFrom === "left" &&
      startPosition === Positions.Left &&
      `
      30% {
        transform: scale(1,1);
        opacity: ${startOpacity};
      }
      `
    }
    ${
      turnFrom === "right" &&
      startPosition === Positions.Right &&
      `
      30% {
        transform: scale(1,1);
        opacity: ${startOpacity};
      }
      `
    }
    ${
      startPosition === Positions.Center &&
      `
      50% {
        transform: ${`scale(${endScale},${endScale})`};
        margin-right: ${endMargin}px;
      }
      `
    }
    100% {    
      transform: ${`scale(${endScale},${endScale})`};
      margin-right: ${endMargin}px;
      opacity: ${endOpacity};
    }
    `;
  } else {
    return keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
  `;
  }
}

function makeFadeInKeyframe(
  turnFrom: "left" | "right",
  moveAmount: number,
): Keyframes {
  const startScale = 0.645;
  let endScale = 0.645;
  const startMargin = turnFrom === "left" ? 1803 : -1803;
  const pn = turnFrom === "left" ? 1 : -1;
  let endMargin = 1803 * pn;
  switch (moveAmount) {
    case 1:
      endMargin = 1249 * pn;
      break;
    case 2:
      endMargin = 695 * pn;
      break;
    case 3:
      endMargin = 0;
      endScale = 1;
      break;
    case 4:
      endMargin = -695 * pn;
      break;
    case 5:
      endMargin = -1249 * pn;
      break;
    default:
      break;
  }
  return keyframes`
  0% {
    transform: ${`scale(${startScale},${startScale})`};
    margin-right: ${startMargin}px;
    opacity: 0;
  }
  ${
    moveAmount >= 2 &&
    `
  10% {
    transform: ${`scale(${startScale},${startScale})`};
    margin-right: ${startMargin}px;
    opacity: 0;
  }
  `
  }
  100% {    
    transform: ${`scale(${endScale},${endScale})`};
    margin-right: ${endMargin}px;
    opacity: 1;
    ${moveAmount === 3 && `filter: drop-shadow(${BannerShadow});`}
  }
  `;
}

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  padding: 36px 0;
  background: ${Color.Background.Light};

  @media ${device.mobile} {
    padding: 0 0 18px 0;
  }
`;

const CardsWrapper = styled.div<{
  height: string;
}>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;

  @media ${device.mobile} {
    transform: scale(
      ${MOBILE_BANNER_WIDTH / PC_BANNER_WIDTH},
      ${MOBILE_BANNER_WIDTH / PC_BANNER_WIDTH}
    );
  }
`;
const BannerWrapper = styled.div<{
  index: number;
  length: number;
  selectedIndex: number;
  targetIndex: number;
  turnFrom: TurnFrom;
  moveAmount: number;
  duringAnimation: boolean;
  height: string;
  width: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height};
  position: absolute;
  ${({ index, targetIndex }) =>
    index === targetIndex && `filter: drop-shadow(${BannerShadow});`}
  animation-name: ${({
    index,
    selectedIndex,
    targetIndex,
    length,
    turnFrom,
    moveAmount,
    duringAnimation,
  }) =>
    duringAnimation && turnFrom
      ? moveAmount === 1
        ? makeKeyframeAmount1(
            index,
            selectedIndex,
            targetIndex,
            length,
            turnFrom,
          )
        : moveAmount === 2
          ? makeKeyframeAmount2(
              index,
              selectedIndex,
              targetIndex,
              length,
              turnFrom,
            )
          : moveAmount === 3
            ? makeKeyframeAmount3(
                index,
                selectedIndex,
                targetIndex,
                length,
                turnFrom,
              )
            : makeKeyframeAmount4(index, selectedIndex, length, turnFrom)
      : ""};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  ${({ index, selectedIndex, length }) => {
    const startPosition = calcPosition(
      calcPositionIndex(index, selectedIndex, length),
      length,
    );
    const startScale = calcScale(startPosition);
    const startMargin = calcMargin(startPosition);
    const startOpacity = calcOpacity(startPosition);
    return `
      transform: ${`scale(${startScale},${startScale})`};
      margin-right: ${startMargin}px;
      opacity: ${startOpacity};      
      `;
  }}
  ${({ index, targetIndex }) =>
    index === targetIndex &&
    `
    & > .mask {
    opacity: 0;
  }
`}
`;

const FadeInCardWrapper = styled.div<{
  turnFrom: "left" | "right";
  duringAnimation: boolean;
  moveAmount: number;
  height: string;
  width: number;
}>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height};
  animation-name: ${({ duringAnimation, turnFrom, moveAmount }) =>
    duringAnimation ? makeFadeInKeyframe(turnFrom, moveAmount) : ""};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  margin-right: ${({ turnFrom }) =>
    turnFrom === "left" ? "1803px" : "-1803px"};
  transform: scale(0.645, 0.645);
  opacity: 0;
`;

const _CarouselIndicator = styled(CarouselIndicator)`
  margin-top: 28px;

  @media ${device.mobile} {
    margin-top: 0;
  }
`;

export default BannerSlider;
